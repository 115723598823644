body {
    --white: #EAEAEA;
    --gray: #B2B2B2;

    --text: 1.2em;
    --headerSmall: 2.5em;
    --headerBig: 3em;

    margin: 0;
    font-family: Mulish, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #162447;

    scroll-behavior: smooth;
}

.header {
    font-size: var(--headerBig);
    color: var(--white);
    font-weight: bold;
    text-align: center;
    margin: 0 0 70px;
}

@media only screen and (max-width: 800px) {
    body {
        --headerSmall: 2em;
        --headerBig: 2.5em;
    }

    .header {
        margin-bottom: 40px;
    }
}

@keyframes fadeIn {
    0% {
        transform: translateY(-10px);
        opacity: .3;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}