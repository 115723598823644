.intro-section {
    display: flex;
    min-height: 100svh;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.intro-section::before {
    content: '';
    display: block;
    background: linear-gradient(180deg, #1B1B2F 0%, #162447 90%);
    height: 100svh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}

.intro-section .text {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1;
    animation: fadeIn 400ms;
}

.intro-section h1 {
    font-size: calc(var(--headerBig) * 1.3);
    font-weight: bold;
    color: var(--white);
    margin: 8px 0;
}

.intro-section .header-text {
    font-size: var(--headerSmall);
    font-weight: bold;
    color: var(--gray);
    margin: 0;
}

.intro-section .text .desc {
    margin-top: 30px;
    font-size: var(--text);
    color: var(--gray);
}

.intro-section .icon {
    height: 55px;
    margin-right: 10px;
    margin-top: 10px;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    .intro-section .text {
        text-align: center;
    }

    .intro-section .hello {
        display: none;
    }
}
