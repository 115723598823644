.project {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: auto auto 70px;
    gap: 20px;
}

.project:nth-child(odd) {
    flex-flow: row-reverse wrap;
}

.project > * {
    flex-basis: 45%;
}

.project h3 {
    margin: 0 0 10px;
    color: var(--white);
    font-size: var(--headerSmall);
}

.project p {
    color: var(--gray);
    margin: 0;
    font-size: var(--text);
}

.project .img-container {
    align-self: center;
}

.project img {
    min-width: 300px;
    max-width: 100%;
    border-radius: 5px;
    box-shadow: rgb(255, 255, 255, .3) 0 0 0 3px;
    user-select: none;
}

@media only screen and (max-width: 800px) {
    .project {
        justify-content: center;
    }
    
    .project > * {
        flex-basis: 90%;
    }

    .project {
        text-align: center;
    }
}
