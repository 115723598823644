footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: var(--gray);
    padding-bottom: 60px;
}

footer .contact h2 {
    font-size: var(--headerSmall);
    color: var(--white);
    margin: 0 0 10px;
}

footer .contact a {
    margin: 0;
}

footer .contact a {
    font-size: var(--text);
    margin: 0;
    color: var(--gray);
    text-decoration: none;
}

footer .contact a:hover {
    text-decoration: underline;
    cursor: pointer;
}

footer .other {
    display: flex;
    gap: 15px;
}

footer .other svg {
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: color 100ms;
    color: var(--gray);
}

footer .other svg:hover {
    color: var(--white);
}